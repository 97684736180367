export default function staffReducer(state=[], action) {
  switch ( action.type ) {
    case 'FETCH_STAFF':
    return action.payload
    case 'CLEAR_STAFF':
    return []
  default:
    return state;
  }
};
