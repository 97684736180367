import { React, useState, useRef, useEffect } from "react";

import _ from "lodash";
import { MapContainer, TileLayer, GeoJSON, Popup } from "react-leaflet";
import { connect } from "react-redux";
import { DotLoader } from "react-spinners";
import { bindActionCreators } from "redux";

import Error from "./error";
import Legend from "./legend";
import MapPopup from "./mapPopup";
import { fetchLegislators } from "../utilities/apiWrapper";
import { fieldNames } from "../utilities/constants";
import "leaflet/dist/leaflet.css";
import "../styles/legislativeMap.css";

function LegislativeMap(props) {
  const geoMap = useRef(null);
  const [geojsonData, setGeojsonData] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [districtNum, setDistrictNum] = useState(null);
  const [coordinates, setCoordinates] = useState({ lat: 40.7, lng: -74 });
  const [isLoading, setIsLoading] = useState("");
  const [error, setError] = useState(false);
  const { DISTRICT_REPRESENTED, LEGISLATIVE_CONFERENCE, LAST_NAME } =
    fieldNames;

  // people is set/updated in mapStateToProps so cannot be a const
  // TODO once we refactor to get rid of Redux, we can deconstruct props when they're passed in
  let people = props.people;
  const governmentBody = props.governmentBody;
  const addLegislatorAction = props.addLegislatorAction;
  const fetchLegislatorsAction = props.fetchLegislatorsAction;
  const mapDataUrl = props.mapDataUrl;
  const mapData = props.mapData;
  const districtFieldName = props.districtFieldName;

  useEffect(() => {
    const fetchMapData = () => {
      fetch(mapDataUrl)
        .then((results) => {
          return results.json();
        })
        .then((results) => {
          setGeojsonData(results);
          applyStyles(results);
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    };

    const applyStyles = (mapData) => {
      let districtType =
        typeof mapData.features[0].properties[districtFieldName];

      let [
        democraticDistricts,
        republicanDistricts,
        independentDistricts,
        undecidedDistricts,
      ] = setParty(districtType);

      geoMap.current.setStyle(function (feature) {
        let district = feature.properties[districtFieldName];
        let blue = "#113ccf";
        let red = "#a2251b";
        let color = democraticDistricts.includes(district)
          ? blue
          : republicanDistricts.includes(district)
          ? red
          : "white";

        return {
          weight: 1,
          fillColor: color,
          color: "gray",
        };
      });
      setIsLoading("not-loading");
    };

    const setParty = (districtType) => {
      let democraticDistricts = [];
      let republicanDistricts = [];
      let independentDistricts = [];
      let undecidedDistricts = [];
      people.forEach((person) => {
        let districtRepresented = person.fields[DISTRICT_REPRESENTED][0];
        let legislativeConference = person.fields[LEGISLATIVE_CONFERENCE];

        if (districtType === "number") {
          districtRepresented = parseInt(districtRepresented);
        }
        if (legislativeConference === "Democratic Conference") {
          democraticDistricts.push(districtRepresented);
        } else if (legislativeConference === "Republican Conference") {
          republicanDistricts.push(districtRepresented);
        } else if (legislativeConference === "Independent Conference") {
          independentDistricts.push(districtRepresented);
        } else {
          undecidedDistricts.push(districtRepresented);
        }
      });
      return [
        democraticDistricts,
        republicanDistricts,
        independentDistricts,
        undecidedDistricts,
      ];
    };

    if (people.length === 0) {
      fetchLegislatorsAction({
        governmentBody: governmentBody,
        addLegislatorAction: addLegislatorAction,
      });
    } else {
      fetchMapData();
    }
  }, [
    people,
    governmentBody,
    addLegislatorAction,
    fetchLegislatorsAction,
    mapDataUrl,
    mapData,
    districtFieldName,
  ]);

  function onEachFeature(feature, layer) {
    let districtNum = feature.properties[props.districtFieldName].toString();

    layer.on("click", function (event) {
      const [rep] = people.filter((person) => {
        return person.fields[DISTRICT_REPRESENTED][0] === districtNum;
      });
      if (rep.fields[LAST_NAME] === "Undecided") {
        setPopupInfo(false);
        setShowPopup(true);
        setDistrictNum(rep.fields[DISTRICT_REPRESENTED][0]);
      } else {
        setPopupInfo(rep.fields);
        setShowPopup(true);
      }
      setCoordinates(event.latlng);
    });
    layer.bindTooltip(districtNum, {
      permanent: true,
      direction: "center",
      className: "district-labels",
    });
  }

  return (
    <div className="map-container">
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        center={props.center}
        zoom={props.zoom}
        scrollWheelZoom={true}
      >
        <Legend />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        />

        {geojsonData && (
          <GeoJSON
            data={geojsonData}
            ref={geoMap}
            onEachFeature={onEachFeature}
          />
        )}

        {showPopup && (
          <Popup position={coordinates}>
            {!!popupInfo ? (
              <MapPopup rep={popupInfo} />
            ) : (
              <div>
                Distict {districtNum}: This race is too close to call. Check
                back in the coming weeks for updates.
              </div>
            )}
          </Popup>
        )}
      </MapContainer>

      {error ? (
        <Error errorType="mapUrl" />
      ) : (
        <div className={isLoading}>
          <div className="loading-overlay">
            <div className="spinner">
              <DotLoader
                size={100}
                color={"rgb(235, 126, 90)"}
                loading={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const people = _.get(state, [ownProps.governmentBodyShort], []);

  return {
    people: people,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLegislatorsAction: bindActionCreators(fetchLegislators, dispatch),
  };
}

const componentCreator = connect(mapStateToProps, mapDispatchToProps);
export default componentCreator(LegislativeMap);
