// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    height: 100vh;
}

.map-container {
    height: calc(100vh - 95px);
    margin: auto;
    position: relative;
    overflow-y: hidden;
}

.mapboxgl-map {
    overflow: visible;
}

.leaflet-tooltip.district-labels {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    font-size: 12px;
    color: rgb(111, 111, 111);
}

@media (min-width: 992px) {
    .map-container {
        height: calc(100vh - 151px);
    }
}

/*allows legend to be visible on top of map*/
.leaflet-control {
    z-index: 0 !important
}

.leaflet-pane {
    z-index: 0 !important
}

.leaflet-top, .leaflet-bottom {
    z-index: 0 !important
}`, "",{"version":3,"sources":["webpack://./src/styles/legislativeMap.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA,4CAA4C;AAC5C;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ","sourcesContent":["body {\n    height: 100vh;\n}\n\n.map-container {\n    height: calc(100vh - 95px);\n    margin: auto;\n    position: relative;\n    overflow-y: hidden;\n}\n\n.mapboxgl-map {\n    overflow: visible;\n}\n\n.leaflet-tooltip.district-labels {\n    background-color: transparent;\n    border: transparent;\n    box-shadow: none;\n    font-size: 12px;\n    color: rgb(111, 111, 111);\n}\n\n@media (min-width: 992px) {\n    .map-container {\n        height: calc(100vh - 151px);\n    }\n}\n\n/*allows legend to be visible on top of map*/\n.leaflet-control {\n    z-index: 0 !important\n}\n\n.leaflet-pane {\n    z-index: 0 !important\n}\n\n.leaflet-top, .leaflet-bottom {\n    z-index: 0 !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
