// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend-container {
    max-width: 270px;
    position: absolute;
    top: 1.5vh;
    right: 0;
    margin: 20px;
    padding: 20px;
    background-color: white;
    opacity: .7;
    font-family: 'Helvetica', Arial, sans-serif;
    box-shadow: 0 0 16px rgb(0 0 0 / 24%);
}

.hide-legend {
    position: absolute;
    top: 1.5vh;
    right: 0;
    margin: 20px;
    padding: 20px;
    background-color: white;
    opacity: .7;
    box-shadow: 0 0 16px rgb(0 0 0 / 24%);
}

/*hide every element except toggle icon in the legend*/
.hide-legend>*:not(.toggle-legend-icon) {
    display: none;
}

.hide-legend .toggle-legend-icon {
    font-size: 20px;
}

.toggle-legend-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}


.toggle-legend-icon:hover, .toggle-legend-icon:focus {
    cursor: pointer;
}

.democrat-legend, .republican-legend {
    display: flex;
    align-items: center;
    gap: 5px;
}

.democrat-legend .circle {
    background-color: #113ccf;
    opacity: .5;
}

.republican-legend .circle {
    background-color: #a2251b;
    opacity: .5;
}

.circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.alert-text {
    font-weight: 700;
    margin: 0;
    margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/legend.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,2CAA2C;IAC3C,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,qCAAqC;AACzC;;AAEA,sDAAsD;AACtD;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb;;;AAGA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,SAAS;IACT,eAAe;AACnB","sourcesContent":[".legend-container {\n    max-width: 270px;\n    position: absolute;\n    top: 1.5vh;\n    right: 0;\n    margin: 20px;\n    padding: 20px;\n    background-color: white;\n    opacity: .7;\n    font-family: 'Helvetica', Arial, sans-serif;\n    box-shadow: 0 0 16px rgb(0 0 0 / 24%);\n}\n\n.hide-legend {\n    position: absolute;\n    top: 1.5vh;\n    right: 0;\n    margin: 20px;\n    padding: 20px;\n    background-color: white;\n    opacity: .7;\n    box-shadow: 0 0 16px rgb(0 0 0 / 24%);\n}\n\n/*hide every element except toggle icon in the legend*/\n.hide-legend>*:not(.toggle-legend-icon) {\n    display: none;\n}\n\n.hide-legend .toggle-legend-icon {\n    font-size: 20px;\n}\n\n.toggle-legend-icon {\n    position: absolute;\n    right: 10px;\n    top: 10px;\n}\n\n\n.toggle-legend-icon:hover, .toggle-legend-icon:focus {\n    cursor: pointer;\n}\n\n.democrat-legend, .republican-legend {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}\n\n.democrat-legend .circle {\n    background-color: #113ccf;\n    opacity: .5;\n}\n\n.republican-legend .circle {\n    background-color: #a2251b;\n    opacity: .5;\n}\n\n.circle {\n    width: 8px;\n    height: 8px;\n    border-radius: 100%;\n}\n\n.alert-text {\n    font-weight: 700;\n    margin: 0;\n    margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
