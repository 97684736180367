import { combineReducers } from "redux";

import assemblyReducer from "./assemblyReducer";
import buffaloReducer from "./buffaloReducer";
import councilReducer from "./councilReducer";
import dutchessReducer from "./dutchessReducer";
import erieReducer from "./erieReducer";
import errorReducer from "./errorReducer";
import loadingReducer from "./loadingReducer";
import monroeReducer from "./monroeReducer";
import nassauReducer from "./nassauReducer";
import orangeReducer from "./orangeReducer";
import putnamReducer from "./putnamReducer";
import rochesterReducer from "./rochesterReducer";
import senateReducer from "./senateReducer";
import staffReducer from "./staffReducer";
import suffolkReducer from "./suffolkReducer";
import ulsterReducer from "./ulsterReducer";
import westchesterReducer from "./westchesterReducer";

const rootReducer = combineReducers({
  assembly: assemblyReducer,
  senate: senateReducer,
  council: councilReducer,
  isError: errorReducer,
  isLoading: loadingReducer,
  staff: staffReducer,
  nassau: nassauReducer,
  suffolk: suffolkReducer,
  dutchess: dutchessReducer,
  orange: orangeReducer,
  putnam: putnamReducer,
  ulster: ulsterReducer,
  westchester: westchesterReducer,
  buffalo: buffaloReducer,
  erie: erieReducer,
  monroe: monroeReducer,
  rochester: rochesterReducer,
});

export default rootReducer;
