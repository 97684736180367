import React from "react";

import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import { SocialIcon } from "react-social-icons";

import { countyLegislatures, fieldNames } from "../utilities/constants";
import utils from "../utilities/utils";

const {
  RACE,
  GENDER,
  SEXUAL_ORIENTATION,
  IN_OFFICE_SINCE,
  TITLE,
  LEADERSHIP_TITLE,
  CHAIRPERSONSHIP,
  VICE_CHAIRPERSONSHIP,
  LEGISLATIVE_CONFERENCE,
  COMMITTEE_ASSIGNMENTS_AND_CAUCUSES,
  FACEBOOK,
  INSTAGRAM,
  TWITTER,
  FIRST_NAME,
  LAST_NAME,
  FULL_NAME_TITLE,
  PARTY,
  DISTRICT_REPRESENTED,
  IMAGE,
  RANKER_FOR,
  DISTRICT_OFFICE_ADDRESS,
  CAPITOL_OFFICE_ADDRESS,
  OTHER_OFFICE_ADDRESS,
  DISTRICT_OFFICE_PHONE,
  CAPITOL_OFFICE_PHONE,
  OTHER_OFFICE_PHONE,
  DISTRICT_OFFICE_FAX,
  CAPITOL_OFFICE_FAX,
  EMAIL,
  EMAIL_2,
  WEBSITE,
  MAP_IMAGE,
  NEIGHBORHOODS,
} = fieldNames;

function electedPopup(props) {
  const renderChairpersonship = () => {
    const chairperson = props.fields[CHAIRPERSONSHIP];
    if (chairperson && chairperson.length > 0) {
      return (
        <h6 className="chairpersonship">
          Chair of Committee(s):
          {props.fields[CHAIRPERSONSHIP].map((committee, i) =>
            i > 0 ? "; " + committee : " " + committee
          )}
        </h6>
      );
    } else {
      return null;
    }
  };

  const renderViceChairpersonship = () => {
    const viceChairPerson = props.fields[VICE_CHAIRPERSONSHIP];
    if (viceChairPerson && viceChairPerson.length > 0) {
      return (
        <h6 className="chairpersonship">
          Vice Chair of Committee(s):
          {props.fields[VICE_CHAIRPERSONSHIP].map((committee, i) =>
            i > 0 ? "; " + committee : " " + committee
          )}
        </h6>
      );
    } else {
      return null;
    }
  };

  const renderRanker = () => {
    const rankerFor = props.fields[RANKER_FOR];
    if (rankerFor && rankerFor.length > 0) {
      return (
        <h6 className="chairpersonship">
          Ranker of Committee(s):
          {rankerFor.map((committee, i) =>
            i > 0 ? "; " + committee : " " + committee
          )}
        </h6>
      );
    } else {
      return null;
    }
  };

  const renderTitleText = () => {
    const leadershipTitle = props.fields[LEADERSHIP_TITLE];
    if (leadershipTitle) {
      return (
        <div className="person-title-info">
          <h5>{leadershipTitle}</h5>
          {renderTitleAndInOfficeSince()}
        </div>
      );
    } else {
      return (
        <div className="person-title-info">{renderTitleAndInOfficeSince()}</div>
      );
    }
  };

  const renderTitleAndInOfficeSince = () => {
    const inOfficeSince = props.fields[IN_OFFICE_SINCE];
    return (
      <h5>
        {props.fields[TITLE]}
        {/* "In Office Since" results in an error if it is blank in Airtable. We need to check that a 
          string value is returned to avoid the app breaking on the error. */}
        {typeof inOfficeSince === "string" && (
          <span className="unbold-text"> since {inOfficeSince} </span>
        )}
      </h5>
    );
  };

  const renderEmail = (email) => {
    if (props.fields[email]) {
      return (
        <h5>
          {email}
          <br />
          <a className="modal-bold" href={`mailto:${props.fields[email]}`}>
            {props.fields[email]}
          </a>
        </h5>
      );
    }
  };

  const renderWebsiteInfo = (website) => {
    if (website) {
      return (
        <h5>
          <a
            className="modal-bold"
            href={website}
            target="_blank"
            rel="noreferrer"
          >
            Official Website
          </a>
        </h5>
      );
    }
  };

  const renderIdentities = () => {
    let race = props.fields[RACE];
    const gender = props.fields[GENDER];
    const sexualOrientation = props.fields[SEXUAL_ORIENTATION];
    const identities = [race, gender, sexualOrientation];
    const knownIdentities = identities.filter((identity) => {
      return identity && identity !== "Unknown";
    });
    const strIdentities = knownIdentities.join(", ");
    return <span className="modal-bold">{strIdentities}</span>;
  };

  const renderPhone = (phone) => {
    if (props.fields[phone]) {
      return (
        <h5>
          {phone.includes("Phone") ? "Tel: " : "F: "}
          <span className="modal-bold">{props.fields[phone]}</span>
        </h5>
      );
    }
  };

  const renderAddress = (address) => {
    if (props.fields[address]) {
      return (
        <h5>
          <a
            className="modal-bold"
            href={`https://maps.google.com/?q=${props.fields[address]}`}
            target="_blank"
            rel="noreferrer"
          >
            {props.fields[address]}
          </a>
        </h5>
      );
    }
  };

  const renderOffice1 = () => {
    let office;
    if (countyLegislatures.includes(props.governmentBody)) {
      office = "Legislative Office";
    } else {
      office = "District Office";
    }

    let officeAddress = renderAddress(DISTRICT_OFFICE_ADDRESS);
    let officePhone = renderPhone(DISTRICT_OFFICE_PHONE);
    let officeFax = renderPhone(DISTRICT_OFFICE_FAX);

    return officeAddress || officePhone || officeFax ? (
      <div>
        <h4 className="office-title">{office}</h4>
        {officeAddress}
        {officePhone}
        {officeFax}
      </div>
    ) : null;
  };

  const renderOffice2 = () => {
    let governmentBody = props.governmentBody;
    if (!countyLegislatures.includes(governmentBody)) {
      let office;
      if (governmentBody === "Senate" || governmentBody === "Assembly") {
        office = "Albany Office";
      } else {
        office = "Legislative Office";
      }

      let officeAddress = renderAddress(CAPITOL_OFFICE_ADDRESS);
      let officePhone = renderPhone(CAPITOL_OFFICE_PHONE);
      let officeFax = renderPhone(CAPITOL_OFFICE_FAX);

      return officeAddress || officePhone || officeFax ? (
        <div>
          <h4 className="office-title">{office}</h4>
          {officeAddress}
          {officePhone}
          {officeFax}
        </div>
      ) : null;
    }
  };

  const renderOtherContactInfo = () => {
    var address3 = renderAddress(OTHER_OFFICE_ADDRESS);
    var phone3 = renderPhone(OTHER_OFFICE_PHONE);
    return address3 || phone3 ? (
      <div>
        <h4 className="office-title">Other Contact Info</h4>
        {address3}
        {phone3}
      </div>
    ) : null;
  };

  const haveContactInfo = () => {
    return renderOffice1() || renderOffice2() || renderOtherContactInfo();
  };

  const addAtSymbol = (handleStr) => {
    if (handleStr && handleStr[0] !== "@") {
      handleStr = "@" + handleStr;
    }
    return handleStr;
  };

  const renderSocialMedia = () => {
    let socialMedia = [];
    const facebook = props.fields[FACEBOOK];
    const instagram = addAtSymbol(props.fields[INSTAGRAM]);
    const twitter = addAtSymbol(props.fields[TWITTER]);

    if (facebook) {
      let facebookObj = {
        text: "",
        link: facebook,
      };
      socialMedia.push(facebookObj);
    }
    if (instagram) {
      let instagramObj = {
        text: instagram,
        link: `https://instagram.com/${instagram.slice(1)}`,
      };
      socialMedia.push(instagramObj);
    }
    if (twitter) {
      let twitterObj = {
        text: twitter,
        link: `https://twitter.com/${twitter.slice(1)}`,
      };
      socialMedia.push(twitterObj);
    }

    return socialMedia.length > 0 ? (
      <div className="social-container">
        {socialMedia.map((site, i) => (
          <div key={i} className="social-media">
            <SocialIcon url={site.link} style={{ height: 25, width: 25 }} />
            <h5 className="social-text">{site.text}</h5>
          </div>
        ))}
      </div>
    ) : null;
  };

  const showStaff = () => {
    if (props.staff.length > 0) {
      return (
        <div>
          <h4>Personal Staff</h4>
          {props.staff.map((staffer, i) => (
            <h6 key={i}>
              <div className="modal-bold">
                {staffer.fields[FULL_NAME_TITLE]}
              </div>
            </h6>
          ))}
        </div>
      );
    } else {
      return <div className="staff" />;
    }
  };

  const showCommittees = () => {
    let groups = props.fields[COMMITTEE_ASSIGNMENTS_AND_CAUCUSES];

    return groups ? (
      <div>
        <h4>Committees & Caucuses</h4>
        {groups.map((group, i) => (
          <h6 key={i}>
            <div className="modal-bold">{`${group}`}</div>
          </h6>
        ))}
      </div>
    ) : null;
  };

  const showNeighborhoods = () => {
    let neighborhoods = props.fields[NEIGHBORHOODS];
    if (neighborhoods) {
      return (
        <div>
          <h6>
            <span className="bold-text">
              Neighborhoods, Cities, and Counties:
            </span>{" "}
            {neighborhoods}
          </h6>
        </div>
      );
    }
  };
  return (
    <Modal
      size="lg"
      centered
      show={props.show}
      onHide={props.onHide}
      className="person-container"
    >
      <Modal.Header closeButton>
        <Container className="person-info">
          <Row>
            {props.fields[IMAGE] && (
              <Col xs={3}>
                <img
                  className="modal-image"
                  src={props.fields[IMAGE][0].url}
                  width={100}
                  height={100}
                  alt="legislator headshot"
                />
              </Col>
            )}
            <Col xs={8} sm={9}>
              <Row>
                {renderTitleText()}
                <h4 className="full-name-title">
                  {props.fields[FIRST_NAME] +
                    " " +
                    props.fields[LAST_NAME] +
                    " "}
                  <span className="party-title">
                    ({props.fields[PARTY] && props.fields[PARTY].join(", ")})
                  </span>
                </h4>
                <h5 className="leg-conference">
                  {props.fields[LEGISLATIVE_CONFERENCE]}
                </h5>
                {renderChairpersonship()}
                {renderViceChairpersonship()}
                {renderRanker()}
              </Row>
            </Col>
          </Row>
          <Row>
            {props.fields[MAP_IMAGE] && (
              <Col xs={3}>
                <img
                  src={props.fields[MAP_IMAGE][0].url}
                  className="district-image"
                  alt="district shape"
                />
              </Col>
            )}
            <Col xs={8} sm={9}>
              <h5 className="district-num">
                District {props.fields[DISTRICT_REPRESENTED]}
              </h5>
              {showNeighborhoods()}
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            {/*Only render this column if we have contact info to show*/}
            {haveContactInfo() && (
              <Col xs={12} lg={6}>
                <div>
                  {renderOffice1()}
                  {renderOffice2()}
                  {renderOtherContactInfo()}
                </div>
                <br />
              </Col>
            )}
            <Col xs={12} lg={6}>
              {renderEmail(EMAIL)}
              {renderEmail(EMAIL_2)}
              {renderWebsiteInfo(props.fields[WEBSITE])}
              {renderSocialMedia()}
              <h5>
                Seems to identify as:
                <p>{renderIdentities()}</p>
              </h5>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col xs={12} md={12} lg={6}>
              {showStaff()}
            </Col>
            <Col xs={12} md={12} lg={6}>
              {showCommittees()}
            </Col>
          </Row>
          <br />
          {props.staff.length > 0 && <div>{utils.showNotice(true)}</div>}
          <div className="modal-close-btn">
            <Button onClick={props.onClick}>Close</Button>
          </div>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
export default electedPopup;
