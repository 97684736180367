import React from "react";

import "../index.css";

function Footer() {
  return (
    <div className="footer">
      <div>
        <a href="/privacy-policy" className="privacy-policy">
          Privacy Policy
        </a>
        <span className="vr"></span>
        <a href="/terms-of-service" className="terms-of-service">
          Terms of Service
        </a>
      </div>
      <p className="copyright">© Copyright 2020. The Advocacy Institute.</p>
    </div>
  );
}
export default Footer;
