const base_id = process.env.REACT_APP_AIRTABLE_BASE_ID;
const personal_access_token =
  process.env.REACT_APP_AIRTABLE_PERSONAL_ACCESS_TOKEN;

export function fetchStaff(governmentBody, name) {
  const encodedName = encodeURI(name);
  const staffers = fetch(
    `https://api.airtable.com/v0/${base_id}/People?view=${governmentBody}+Staff&filterByFormula=FIND(%22${encodedName}%22%2C%7BReports+to+Elected%7D)`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${personal_access_token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((staff) => {
      return staff.records;
    });
  return {
    type: "FETCH_STAFF",
    payload: staffers,
  };
}

export function clearStaff() {
  return {
    type: "CLEAR_STAFF",
  };
}
