import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as staffActions from "../actions/staffActions";
import { fieldNames } from "../utilities/constants";
import utils from "../utilities/utils";
import ElectedPopup from "./electedPopup";
import UndecidedPopup from "./undecidedPopup";
import VacantPopup from "./vacantPopup";

const {
  RACE,
  GENDER,
  CHAIRPERSONSHIP,
  LEGISLATIVE_CONFERENCE,
  COMMITTEE_ASSIGNMENTS_AND_CAUCUSES,
  LAST_NAME,
  FULL_NAME_TITLE,
  DISTRICT_REPRESENTED,
  REGION,
  PREVIEW,
} = fieldNames;

class LegislativePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.filter = this.filter.bind(this);
    this.buttonHandler = this.buttonHandler.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  buttonHandler(event) {
    event.preventDefault();
    const governmentBody = this.props.governmentBody;

    if (governmentBody.includes("Senate")) {
      this.props.actions.fetchStaff(
        "Senate",
        this.props.info.fields[FULL_NAME_TITLE]
      );
    } else if (governmentBody.includes("Assembly")) {
      this.props.actions.fetchStaff(
        "Assembly",
        this.props.info.fields[FULL_NAME_TITLE]
      );
    } else if (governmentBody === "NYC Council") {
      this.props.actions.fetchStaff(
        this.props.governmentBody,
        this.props.info.fields[FULL_NAME_TITLE]
      );
    }
    this.open();
  }

  raceHide() {
    let hide = false;

    if (this.props.raceFilter !== "") {
      hide = true;
      this.props.info.fields[RACE].forEach((race) => {
        if (this.props.raceFilter.includes(race)) {
          hide = false;
        }
      });
    }
    return hide;
  }

  genderHide() {
    return (
      this.props.genderFilter !== "" &&
      !this.props.genderFilter.includes(`${this.props.info.fields[GENDER][0]}`)
    );
  }

  regionHide() {
    const regionFilter = this.props.regionFilter;
    const regions = utils.trimRegion(this.props.info.fields[REGION]);
    let showRegion = false;
    regionFilter &&
      regions.forEach((region) => {
        if (regionFilter.includes(region)) {
          showRegion = true;
        }
      });
    return regionFilter && regions && regionFilter !== "" && !showRegion;
  }

  committeeChair() {
    const chairpersonship = this.props.info.fields[CHAIRPERSONSHIP];
    const committeeFilter = this.props.committeeFilter;
    if (committeeFilter.length > 0 && chairpersonship) {
      let filterCommittees = committeeFilter;
      let chair = "";
      chairpersonship.forEach((committee) => {
        filterCommittees.forEach((filterCommittee) => {
          if (filterCommittee === committee) {
            chair = "true";
          }
        });
      });
      return chair;
    }
  }

  checkCommittees(filterCommittees) {
    if (this.props.committees) {
      let match = filterCommittees.filter((committee) => {
        return this.props.committees.includes(committee);
      });
      return match.length > 0;
    }
  }

  committeeHide() {
    const committeeFilter = this.props.committeeFilter;
    return committeeFilter.length > 0 && !this.checkCommittees(committeeFilter);
  }

  filter() {
    if (
      this.raceHide() ||
      this.genderHide() ||
      this.regionHide() ||
      this.committeeHide()
    ) {
      return {
        visibility: "hidden",
        opacity: 0,
        transition: "visibility 1s, opacity 1s linear",
      };
    }
  }

  close() {
    this.setState({ showModal: false });
    this.props.actions.clearStaff();
  }

  open() {
    this.setState({
      showModal: true,
    });
  }

  get setStyle() {
    let classes = this.props.classes;
    if (this.committeeChair()) {
      classes += " chair-outline";
    }

    if (classes.includes("asian") && classes.includes("republican")) {
      classes += " black-text";
    } else if (
      (classes.includes("white") && !classes.includes("black")) ||
      classes.includes("independent") ||
      classes.includes("republican") ||
      classes.includes("unknown")
    ) {
      classes += " white-text";
    }
    return classes;
  }

  render() {
    return (
      <div>
        <button
          className={this.setStyle}
          onClick={this.buttonHandler}
          style={this.filter()}
        >
          {this.props.info.fields[LAST_NAME]}
        </button>
        {this.props.info.fields[LAST_NAME] === "Undecided" ? ( //renders popup for undecided position
          <UndecidedPopup
            district={this.props.info.fields[DISTRICT_REPRESENTED]}
            show={this.state.showModal}
            onHide={this.close}
          />
        ) : this.props.info.fields[LEGISLATIVE_CONFERENCE].includes(
            "Unknown"
          ) ? ( //renders popup for vacant position
          <VacantPopup
            field={this.props.info.fields}
            show={this.state.showModal}
            onHide={this.close}
            onClick={this.close}
          />
        ) : this.props.info.fields[PREVIEW] ? ( //renders popup for previews
          <ElectedPopup
            fields={this.props.info.fields}
            staff={this.props.staff}
            show={this.state.showModal}
            onHide={this.close}
            onClick={this.close}
            preview={true}
            governmentBody={this.props.governmentBody}
          />
        ) : (
          //renders popup for known position
          <ElectedPopup
            fields={this.props.info.fields}
            staff={this.props.staff}
            show={this.state.showModal}
            onHide={this.close}
            onClick={this.close}
            preview={false}
            governmentBody={this.props.governmentBody}
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state, ownProps) {
  const race = ownProps.info.fields[RACE];

  // turn into "Latinx" for use in class names
  if (race && race.includes("Latino/a")) {
    race[race.indexOf("Latino/a")] = "Latinx";
  }

  // turn into "Arab-Asian" for use in class names
  if (race && race.includes("Arab/Asian")) {
    race[race.indexOf("Arab/Asian")] = "Arab-Asian";
  }

  let committees = ownProps.info.fields[COMMITTEE_ASSIGNMENTS_AND_CAUCUSES];

  let staff = state.staff;
  let classes = "btn-circle ";

  const party = ownProps.info.fields[LEGISLATIVE_CONFERENCE];
  if (party === "Independent Conference") {
    classes += "independent";
  } else if (party.includes("Undecided")) {
    classes += "undecided";
  } else if (race && race[0] !== "Unknown" && party) {
    let raceStr = race.sort().join("-").toLowerCase();
    classes += `${raceStr + "-" + party.toLowerCase().slice(0, -11)}`; //sliced the string to remove 'Conference'
  } else {
    classes += "race-unknown";
  }

  // turn back into Latino/a for use in popup
  if (race && race.includes("Latinx")) {
    race[race.indexOf("Latinx")] = "Latino/a";
  }

  //turn back into Arab/Asian for use in popup
  if (race && race.includes("Arab-Asian")) {
    race[race.indexOf("Arab-Asian")] = "Arab/Asian";
  }

  return {
    classes,
    committees,
    staff,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(staffActions, dispatch) };
}

const componentCreator = connect(mapStateToProps, mapDispatchToProps);

export default componentCreator(LegislativePopup);
