import React, { Component } from "react";

import _ from "lodash";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { bindActionCreators } from "redux";

import LegislativeView from "./legislativeView";
import { fetchLegislators } from "../utilities/apiWrapper";
import {
  legendCategories,
  genderOptions,
  raceOptions,
  regionOptions,
  fieldNames,
} from "../utilities/constants";
import utils from "../utilities/utils";

const { COMMITTEE_ASSIGNMENTS_AND_CAUCUSES, LEGISLATIVE_CONFERENCE } =
  fieldNames;

class LegislativeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genderFilter: "",
      raceFilter: "",
      regionFilter: "",
      committeeFilter: [],
    };
    this.chooseGenderFilter = this.chooseGenderFilter.bind(this);
    this.chooseRaceFilter = this.chooseRaceFilter.bind(this);
    this.chooseRegionFilter = this.chooseRegionFilter.bind(this);
    this.chooseCommitteeFilter = this.chooseCommitteeFilter.bind(this);
    this.getGroupCount = this.getGroupCount.bind(this);
    this.collectLegislativeGroups = this.collectLegislativeGroups.bind(this);
    this.collectLegislativeGroupCounts =
      this.collectLegislativeGroupCounts.bind(this);
  }

  componentDidMount() {
    if (this.props.people.length === 0) {
      this.props.fetchLegislatorsAction({
        governmentBody: this.props.governmentBody,
        addLegislatorAction: this.props.addLegislatorAction,
      });
    }
  }

  chooseGenderFilter(eventKey) {
    this.setState({
      genderFilter: eventKey,
    });
  }

  chooseRaceFilter(eventKey) {
    this.setState({
      raceFilter: eventKey,
    });
  }

  chooseRegionFilter(eventKey) {
    this.setState({
      regionFilter: eventKey,
    });
  }

  chooseCommitteeFilter(eventKey) {
    let values = [];
    eventKey.forEach((choice) => {
      values.push(choice.value);
    });
    this.setState({
      committeeFilter: values,
    });
  }

  collectLegislativeGroups() {
    let independentConference = this.props.independentConference;
    let democraticConference = this.props.democraticConference;
    let republicanConference = this.props.republicanConference;
    let undecidedConference = this.props.undecidedConference;
    let unknownConference = this.props.unknownConference;
    let conservativeConference = this.props.conservativeConference;

    if (undecidedConference.length > 0 && independentConference.length > 0) {
      return {
        Democratic: democraticConference,
        Independent: independentConference,
        Republican: republicanConference,
        Undecided: undecidedConference,
      };
    } else if (undecidedConference.length > 0) {
      return {
        Democratic: democraticConference,
        Republican: republicanConference,
        Undecided: undecidedConference,
      };
    } else if (unknownConference.length > 0) {
      return {
        Democratic: democraticConference,
        Republican: republicanConference,
        Unknown: unknownConference,
      };
    } else if (independentConference.length > 0) {
      return {
        Democratic: democraticConference,
        Independent: independentConference,
        Republican: republicanConference,
      };
    } else if (conservativeConference.length > 0) {
      return {
        Democratic: democraticConference,
        Republican: republicanConference,
        Conservative: conservativeConference,
      };
    } else {
      return {
        Democratic: democraticConference,
        Republican: republicanConference,
      };
    }
  }

  collectLegislativeGroupCounts() {
    let demCount = this.getGroupCount("democraticConference");
    let independentCount = this.getGroupCount("independentConference");
    let repCount = this.getGroupCount("republicanConference");
    let undecidedCount = this.getGroupCount("undecidedConference");
    let unknownCount = this.getGroupCount("unknownConference");
    let conservativeCount = this.getGroupCount("conservativeConference");

    if (
      this.props.undecidedConference.length > 0 &&
      this.props.independentConference.length > 0
    ) {
      return {
        Democratic: demCount,
        Independent: independentCount,
        Republican: repCount,
        Undecided: undecidedCount,
      };
    } else if (this.props.conservativeConference.length > 0) {
      return {
        Democratic: demCount,
        Republican: repCount,
        Conservative: conservativeCount,
      };
    } else if (this.props.undecidedConference.length > 0) {
      return {
        Democratic: demCount,
        Republican: repCount,
        Undecided: undecidedCount,
      };
    } else if (this.props.unknownConference.length > 0) {
      return {
        Democratic: demCount,
        Republican: repCount,
        Unknown: unknownCount,
      };
    } else if (this.props.independentConference.length > 0) {
      return {
        Democratic: demCount,
        Independent: independentCount,
        Republican: repCount,
      };
    } else {
      return {
        Democratic: demCount,
        Republican: repCount,
      };
    }
  }

  getGroupCount(group) {
    if (
      !this.state.genderFilter &&
      !this.state.raceFilter &&
      !this.state.regionFilter &&
      !this.state.committeeFilter
    ) {
      return this.props[group].length;
    } else {
      return utils.updateCounts({
        peopleByGroup: this.props[group],
        genderFilter: this.state.genderFilter,
        raceFilter: this.state.raceFilter,
        regionFilter: this.state.regionFilter,
        committeeFilter: this.state.committeeFilter,
      });
    }
  }

  render() {
    return (
      <div className="houses">
        <div className="house-components">
          {utils.showNotice()}
          <Select
            value={this.state.genderFilter}
            multi={true}
            placeholder="Filter by Gender..."
            simpleValue={true}
            options={genderOptions}
            onChange={this.chooseGenderFilter}
          />
          <Select
            value={this.state.raceFilter}
            multi={true}
            placeholder="Filter by Race..."
            simpleValue={true}
            options={raceOptions}
            onChange={this.chooseRaceFilter}
          />
          {this.props.showRegionFilter && (
            <Select
              value={this.state.regionFilter}
              multi={true}
              placeholder="Filter by Region..."
              simpleValue={true}
              options={regionOptions}
              onChange={this.chooseRegionFilter}
            />
          )}

          {this.props.showCommitteeFilter && (
            <Select
              value={this.state.committeeFilter}
              multi={true}
              placeholder="Filter by Committee..."
              options={utils.setCommitteeOptions(this.props.committeeOptions)}
              onChange={this.chooseCommitteeFilter}
            />
          )}
          <LegislativeView
            governmentBody={this.props.governmentBody}
            groups={this.collectLegislativeGroups()}
            groupCount={this.collectLegislativeGroupCounts()}
            sm={this.props.colSize.sm}
            md={this.props.colSize.md}
            lg={this.props.colSize.lg}
            genderFilter={this.state.genderFilter}
            raceFilter={this.state.raceFilter}
            regionFilter={this.state.regionFilter}
            committeeFilter={this.state.committeeFilter}
            legendCategories={legendCategories}
          />
          <Row>
            <div className="legend">
              <h4>Legend</h4>
              {utils.renderLegend({
                legendCategories: legendCategories,
                people: this.props.people,
                title: "Conference Legislators",
              })}
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const people = _.get(state, [ownProps.governmentBodyShort], []);
  const democraticConference = people.filter((person) =>
    person.fields[LEGISLATIVE_CONFERENCE].includes("Democratic")
  );
  const independentConference = people.filter((person) =>
    person.fields[LEGISLATIVE_CONFERENCE].includes("Independent")
  );
  const republicanConference = people.filter(
    (person) =>
      person.fields[LEGISLATIVE_CONFERENCE].includes("Republican") ||
      (ownProps.governmentBodyShort === "senate" &&
        person.fields[LEGISLATIVE_CONFERENCE].includes(
          "Independent Democratic Conference"
        ))
  );
  const unknownConference = people.filter((person) =>
    person.fields[LEGISLATIVE_CONFERENCE].includes("Unknown")
  );

  const undecidedConference = people.filter((person) =>
    person.fields[LEGISLATIVE_CONFERENCE].includes("Undecided")
  );

  const conservativeConference = people.filter((person) =>
    person.fields[LEGISLATIVE_CONFERENCE].includes("Conservative")
  );

  const allCommittees = people.reduce(function (committees, person) {
    const assignments = person.fields[COMMITTEE_ASSIGNMENTS_AND_CAUCUSES];
    assignments &&
      assignments.forEach((committee) => {
        if (!committees.includes(committee)) {
          committees.push(committee);
        }
      });
    return committees;
  }, []);

  return {
    people: people,
    democraticConference: utils.sortByRaceAndName(democraticConference),
    independentConference: utils.sortByRaceAndName(independentConference),
    republicanConference: utils.sortByRaceAndName(republicanConference),
    conservativeConference: utils.sortByRaceAndName(conservativeConference),
    undecidedConference: undecidedConference,
    unknownConference: unknownConference,
    committeeOptions: allCommittees,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLegislatorsAction: bindActionCreators(fetchLegislators, dispatch),
  };
}

const componentCreator = connect(mapStateToProps, mapDispatchToProps);
export default componentCreator(LegislativeContainer);
