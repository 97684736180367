// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy-container {
    margin-top: 190px;
    padding: 0 100px;
    margin-bottom: 20px;
}


.policy-container p, li {
    color: #858a99;
}

.policy-container a:hover {
    text-decoration: underline;
    text-decoration-color: black;
}

.text-black {
    color: black !important;
}

.underline {
    text-decoration: underline;
}

@media (max-width: 992px) {
    .policy-container {
        margin-top: 125px;
        padding: 0 60px;
    }
}

@media (max-width: 576px) {
    .policy-container {
        margin-top: 120px;
        padding: 0 25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/policies.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;;AAGA;IACI,cAAc;AAClB;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;AAChC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI;QACI,iBAAiB;QACjB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,iBAAiB;QACjB,eAAe;IACnB;AACJ","sourcesContent":[".policy-container {\n    margin-top: 190px;\n    padding: 0 100px;\n    margin-bottom: 20px;\n}\n\n\n.policy-container p, li {\n    color: #858a99;\n}\n\n.policy-container a:hover {\n    text-decoration: underline;\n    text-decoration-color: black;\n}\n\n.text-black {\n    color: black !important;\n}\n\n.underline {\n    text-decoration: underline;\n}\n\n@media (max-width: 992px) {\n    .policy-container {\n        margin-top: 125px;\n        padding: 0 60px;\n    }\n}\n\n@media (max-width: 576px) {\n    .policy-container {\n        margin-top: 120px;\n        padding: 0 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
