import React from "react";

import { Nav, Navbar, NavDropdown, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { DotLoader } from "react-spinners";

import logo from "../logos/ai_logo_transparent_background.png";
import Error from "./error";
import "../styles/navBar.css";

function NavBar(props) {
  const checkActive = (governmentBody) => {
    const path = window.location.pathname.slice(1);
    if (governmentBody === "hudson") {
      let hudsonGovBodies = [
        "dutchess",
        "orange",
        "putnam",
        "ulster",
        "westchester",
        "hudson-valley",
        "hudsonvalley",
      ];
      return hudsonGovBodies.includes(path);
    }

    if (governmentBody === "long-island") {
      let longIslandGovBodies = [
        "nassau",
        "nassau-map",
        "suffolk",
        "suffolk-map",
      ];
      return longIslandGovBodies.includes(path);
    }

    if (governmentBody === "senate") {
      let senateGovBodies = ["senate", "", "senate-map"];
      return senateGovBodies.includes(path);
    }

    if (governmentBody === "assembly") {
      let assemblyGovBodies = ["assembly", "assembly-map"];
      return assemblyGovBodies.includes(path);
    }

    if (governmentBody === "nyc-council") {
      let nycCouncilGovBodies = ["nyc-council", "nyc-council-map"];
      return nycCouncilGovBodies.includes(path);
    }

    if (governmentBody === "western-new-york") {
      let westernNewYorkGovBodies = ["buffalo", "erie"];
      return westernNewYorkGovBodies.includes(path);
    }

    if (governmentBody === "finger-lakes") {
      let fingerLakesGovBodies = ["rochester", "monroe"];
      return fingerLakesGovBodies.includes(path);
    }

    return path === governmentBody;
  };

  let isFixed = window.location.href.includes("map") ? null : "top";

  return (
    <Navbar expand="lg" fixed={isFixed}>
      <Navbar.Brand>
        <div>
          <Nav.Link href="/" className="nav-home-link">
            <img className="nav-logo" src={logo} alt="ai-logo" />
          </Nav.Link>

          <h3 className="ai-title-container">{props.viewTitle}</h3>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Navbar.Brand>

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav variant="tabs" defaultActiveKey="/">
          <NavDropdown title="SENATE" id="1" active={checkActive("senate")}>
            <NavDropdown.Item eventKey="senate" href="/senate">
              VIEW BY PEOPLE
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item eventKey="senate-map" href="/senate-map">
              VIEW ON MAP
            </NavDropdown.Item>
            <Dropdown.Divider />
          </NavDropdown>

          <NavDropdown title="ASSEMBLY" id="2" active={checkActive("assembly")}>
            <NavDropdown.Item eventKey="assembly" href="/assembly">
              VIEW BY PEOPLE
            </NavDropdown.Item>
            <Dropdown.Divider />

            <NavDropdown.Item eventKey="assembly-map" href="/assembly-map">
              VIEW ON MAP
            </NavDropdown.Item>
            <Dropdown.Divider />
          </NavDropdown>

          <NavDropdown
            title="NYC COUNCIL"
            id="3"
            active={checkActive("nyc-council")}
          >
            <NavDropdown.Item eventKey="nyc-council" href="/nyc-council">
              VIEW BY PEOPLE
            </NavDropdown.Item>
            <Dropdown.Divider />

            <NavDropdown.Item
              eventKey="nyc-council-map"
              href="/nyc-council-map"
            >
              VIEW ON MAP
            </NavDropdown.Item>
            <Dropdown.Divider />
          </NavDropdown>

          <NavDropdown
            title="HUDSON VALLEY COUNTIES"
            id="4"
            active={checkActive("hudson")}
          >
            <NavDropdown.Item eventKey="dutchess" href="/dutchess">
              DUTCHESS
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item eventKey="orange" href="/orange">
              ORANGE
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item eventKey="putnam" href="/putnam">
              PUTNAM
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item eventKey="ulster" href="/ulster">
              ULSTER
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item eventKey="westchester" href="/westchester">
              WESTCHESTER
            </NavDropdown.Item>
            <Dropdown.Divider />
          </NavDropdown>
          <NavDropdown
            title="LONG ISLAND COUNTIES"
            id="5"
            active={checkActive("long-island")}
          >
            <NavDropdown.Item eventKey="nassau" href="/nassau">
              NASSAU
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item eventKey="nassau-map" href="/nassau-map">
              VIEW ON MAP
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item eventKey="suffolk" href="/suffolk">
              SUFFOLK
            </NavDropdown.Item>
            <Dropdown.Divider />

            <NavDropdown.Item eventKey="suffolk-map" href="/suffolk-map">
              VIEW ON MAP
            </NavDropdown.Item>
            <Dropdown.Divider />
          </NavDropdown>

          <NavDropdown
            title="WESTERN NY"
            id="4"
            active={checkActive("western-new-york")}
          >
            <NavDropdown.Item eventKey="buffalo" href="/buffalo">
              BUFFALO
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item eventKey="erie" href="/erie">
              ERIE COUNTY
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title="FINGER LAKES"
            id="4"
            active={checkActive("finger-lakes")}
          >
            <NavDropdown.Item eventKey="rochester" href="/rochester">
              ROCHESTER
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item eventKey="monroe" href="/monroe">
              MONROE COUNTY
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>

      <div className={props.isLoading}>
        <div className="loading-overlay">
          <div className="spinner">
            <DotLoader size={100} color={"rgb(235, 126, 90)"} loading={true} />
          </div>
        </div>
      </div>
      {props.children}
      {props.isError ? <Error /> : null}
    </Navbar>
  );
}

function mapStateToProps(state) {
  let viewTitle = "";
  let isLoading = state.isLoading;
  let isError = state.isError;

  let path = window.location.pathname.slice(1);
  switch (path) {
    case "":
    case "senate":
      viewTitle = "State Senate: Legislative Conferences";
      break;
    case "assembly":
      viewTitle = "State Assembly: Legislative Conferences";
      break;
    case "nyc-council":
      viewTitle = "NYC Council: Borough Delegations";
      break;
    case "nassau":
      viewTitle = "Nassau County Legislature: Conferences";
      break;
    case "suffolk":
      viewTitle = "Suffolk County Legislature: Conferences";
      break;
    case "dutchess":
      viewTitle = "Dutchess County Legislature: Conferences";
      break;
    case "orange":
      viewTitle = "Orange County Legislature: Conferences";
      break;
    case "putnam":
      viewTitle = "Putnam County Legislature: Conferences";
      break;
    case "ulster":
      viewTitle = "Ulster County Legislature: Conferences";
      break;
    case "westchester":
    case "hudsonvalley":
    case "hudson-valley":
      viewTitle = "Westchester County Legislature: Conferences";
      break;
    case "buffalo":
      viewTitle = "Buffalo Common Council: Conferences";
      break;
    case "erie":
      viewTitle = "Erie County Legislature: Conferences";
      break;
    case "rochester":
      viewTitle = "Rochester City Council: Conferences";
      break;
    case "monroe":
      viewTitle = "Monroe County Legislature: Conferences";
      break;
    case "nyc-council-map":
      viewTitle = "NYC Council Map";
      break;
    case "senate-map":
      viewTitle = "State Senate Map";
      break;
    case "assembly-map":
      viewTitle = "State Assembly Map";
      break;
    case "suffolk-map":
      viewTitle = "Suffolk Map";
      break;
    case "nassau-map":
      viewTitle = "Nassau Map";
      break;
    case "terms-of-service":
      viewTitle = "Terms of Service";
      isLoading = "not-loading";
      isError = false;
      break;
    case "privacy-policy":
      viewTitle = "Privacy Policy";
      isLoading = "not-loading";
      isError = false;
      break;
    default:
      break;
  }

  return {
    viewTitle,
    isLoading,
    isError,
  };
}
const componentCreator = connect(mapStateToProps);

export default componentCreator(NavBar);
