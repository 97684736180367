import React, { Component } from "react";

import { Routes, Route } from "react-router-dom";

import {
  addCouncilMembers,
  addSenators,
  addAssemblymembers,
  addSuffolkLeg,
  addDutchessLeg,
  addPutnamLeg,
  addUlsterLeg,
  addWestchesterLeg,
  addNassauLeg,
  addOrangeLeg,
  addBuffaloLeg,
  addErieLeg,
  addMonroeLeg,
  addRochesterLeg,
} from "../utilities/apiWrapper";
import {
  mapDataUrls,
  defaultColSize,
  governmentBodies,
} from "../utilities/constants";
import Footer from "./footer";
import LegislativeContainer from "./legislativeContainer";
import LegislativeMap from "./legislativeMap";
import NavBar from "./navBar";
import NycCouncil from "./nycCouncil";
import PrivacyPolicy from "./privacyPolicy";
import TermsOfService from "./termsOfService";

export default class App extends Component {
  render() {
    const {
      nycMapUrl,
      senateMapUrl,
      assemblyMapUrl,
      nassauMapUrl,
      suffolkMapUrl,
    } = mapDataUrls;
    const senate = (
      <LegislativeContainer
        governmentBody={governmentBodies.senate.long}
        governmentBodyShort={governmentBodies.senate.short}
        addLegislatorAction={addSenators}
        showRegionFilter={true}
        showCommitteeFilter={true}
        colSize={{ sm: [12, 12], md: [5, 5], lg: [7, 3] }}
      />
    );

    return (
      <div className="app">
        <NavBar title="NavBar" />
        <Routes>
          <Route exact path="/" element={senate} />
          <Route path="/senate" element={senate} />
          <Route
            path="/senate-map"
            element={
              <LegislativeMap
                governmentBody={governmentBodies.senate.long}
                addLegislatorAction={addSenators}
                mapDataUrl={senateMapUrl}
                governmentBodyShort={governmentBodies.senate.short}
                districtFieldName="DISTRICT"
                center={[42.5, -75]}
                zoom={7}
              />
            }
          />
          <Route
            path="/assembly"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.assembly.long}
                governmentBodyShort={governmentBodies.assembly.short}
                addLegislatorAction={addAssemblymembers}
                showRegionFilter={true}
                showCommitteeFilter={true}
                colSize={{ sm: [12, 12], md: [8, 4], lg: [8, 4] }}
              />
            }
          />
          <Route
            path="/assembly-map"
            element={
              <LegislativeMap
                governmentBody={governmentBodies.assembly.long}
                addLegislatorAction={addAssemblymembers}
                mapDataUrl={assemblyMapUrl}
                governmentBodyShort={governmentBodies.assembly.short}
                districtFieldName="DISTRICT"
                center={[42.5, -75]}
                zoom={7}
              />
            }
          />
          <Route
            path="/nyc-council"
            element={
              <NycCouncil
                governmentBody={governmentBodies.nyc.long}
                governmentBodyShort={governmentBodies.nyc.short}
                showCommitteeFilter={true}
              />
            }
          />
          <Route
            path="/nyc-council-map"
            element={
              <LegislativeMap
                governmentBody={governmentBodies.nyc.long}
                addLegislatorAction={addCouncilMembers}
                mapDataUrl={nycMapUrl}
                governmentBodyShort={governmentBodies.nyc.short}
                districtFieldName="CounDist"
                center={[40.6, -74]}
                zoom={10}
              />
            }
          />
          <Route
            path="/nassau"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.nassau.long}
                governmentBodyShort={governmentBodies.nassau.short}
                addLegislatorAction={addNassauLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/nassau-map"
            element={
              <LegislativeMap
                governmentBody={governmentBodies.nassau.long}
                addLegislatorAction={addNassauLeg}
                mapDataUrl={nassauMapUrl}
                governmentBodyShort={governmentBodies.nassau.short}
                districtFieldName="DISTRICT"
                center={[40.8, -73.6]}
                zoom={9.5}
              />
            }
          />
          <Route
            path="/suffolk"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.suffolk.long}
                governmentBodyShort={governmentBodies.suffolk.short}
                addLegislatorAction={addSuffolkLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/suffolk-map"
            element={
              <LegislativeMap
                governmentBody={governmentBodies.suffolk.long}
                addLegislatorAction={addSuffolkLeg}
                mapDataUrl={suffolkMapUrl}
                governmentBodyShort={governmentBodies.suffolk.short}
                districtFieldName="DISTRICTID"
                center={[40.8, -72.7]}
                zoom={9}
              />
            }
          />
          <Route
            path="/dutchess"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.dutchess.long}
                governmentBodyShort={governmentBodies.dutchess.short}
                addLegislatorAction={addDutchessLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/orange"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.orange.long}
                governmentBodyShort={governmentBodies.orange.short}
                addLegislatorAction={addOrangeLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={{ sm: [12, 12, 12], md: [4, 4, 4], lg: [4, 4, 4] }}
              />
            }
          />
          <Route
            path="/putnam"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.putnam.long}
                governmentBodyShort={governmentBodies.putnam.short}
                addLegislatorAction={addPutnamLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/ulster"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.ulster.long}
                governmentBodyShort={governmentBodies.ulster.short}
                addLegislatorAction={addUlsterLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/hudson-valley"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.westchester.long}
                governmentBodyShort={governmentBodies.westchester.short}
                addLegislatorAction={addWestchesterLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/hudsonvalley"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.westchester.long}
                governmentBodyShort={governmentBodies.westchester.short}
                addLegislatorAction={addWestchesterLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/westchester"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.westchester.long}
                governmentBodyShort={governmentBodies.westchester.short}
                addLegislatorAction={addWestchesterLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/buffalo"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.buffalo.long}
                governmentBodyShort={governmentBodies.buffalo.short}
                addLegislatorAction={addBuffaloLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/erie"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.erie.long}
                governmentBodyShort={governmentBodies.erie.short}
                addLegislatorAction={addErieLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/rochester"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.rochester.long}
                governmentBodyShort={governmentBodies.rochester.short}
                addLegislatorAction={addRochesterLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route
            path="/monroe"
            element={
              <LegislativeContainer
                governmentBody={governmentBodies.monroe.long}
                governmentBodyShort={governmentBodies.monroe.short}
                addLegislatorAction={addMonroeLeg}
                showRegionFilter={false}
                showCommitteeFilter={true}
                colSize={defaultColSize}
              />
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
        <Footer />
      </div>
    );
  }
}
