import React from "react";

import { Modal } from "react-bootstrap";

function error(props) {
  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>

      {props.errorType === "mapUrl" ? (
        <Modal.Body>
          <p>
            Map data for this region is unavailable at this time, please try
            again later.
          </p>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <p>
            We're experiencing some difficulties with our website at this time.
            We're working on it. Please try again later or email
            hello@advocacy-institute.org for assistance.
          </p>
        </Modal.Body>
      )}
    </Modal>
  );
}

export default error;
