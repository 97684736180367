import React, { Component } from "react";

import cx from "classnames";
import { connect } from "react-redux";

import LegislativePopup from "./legislativePopup";

class Group extends Component {
  render() {
    const { governmentBody } = this.props;
    const cls = cx("house-buttons", {
      "assembly-buttons": governmentBody === "Assembly",
      "group-buttons": governmentBody !== "Assembly",
    });
    return (
      <div className={cls}>
        {this.props.representatives.map((person, i) => (
          <LegislativePopup
            key={i}
            info={person}
            genderFilter={this.props.genderFilter}
            raceFilter={this.props.raceFilter}
            regionFilter={this.props.regionFilter}
            committeeFilter={this.props.committeeFilter}
            governmentBody={this.props.governmentBody}
          />
        ))}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.representatives) {
    return {
      representatives: ownProps.representatives,
    };
  } else {
    return { representatives: [{ "": "" }] };
  }
}

const componentCreator = connect(mapStateToProps);
export default componentCreator(Group);
