import React from "react";

import { Modal } from "react-bootstrap";

function undecidedPopup(props) {
  return (
    <Modal
      size="lg"
      centered
      show={props.show}
      onHide={props.onHide}
      className="person-container"
    >
      <Modal.Header closeButton />

      <Modal.Body>
        District {props.district}: This race is too close to call. Check back in
        the coming weeks for updates.
      </Modal.Body>
    </Modal>
  );
}
export default undecidedPopup;
