import React from "react";

import { Row, Col } from "react-bootstrap";

import Group from "./group";

const LegislativeView = (props) => {
  return (
    <div>
      {props.filters}
      <Row className="show-grid">
        {Object.keys(props.groups).map((groupKey, i) => {
          return (
            <Col
              key={groupKey}
              sm={props.sm[i]}
              md={props.md[i]}
              lg={props.lg[i]}
              className="legislative-view-col"
            >
              <Row>
                <Group
                  governmentBody={props.governmentBody}
                  representatives={props.groups[groupKey]}
                  genderFilter={props.genderFilter}
                  raceFilter={props.raceFilter}
                  regionFilter={props.regionFilter}
                  committeeFilter={props.committeeFilter}
                />
              </Row>
              <Row className="house-detail-data">
                <div className="house-detail-title">
                  <h4>
                    {groupKey}:
                    <span style={{ fontWeight: "bold" }}>
                      {" " + props.groupCount[groupKey]}
                    </span>
                  </h4>
                </div>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default LegislativeView;
