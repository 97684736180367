import "../styles/legend.css";
import { useState } from "react";

function Legend() {
  let url = window.location.href;
  let person = "";
  person = url.includes("senate")
    ? (person = "SENATORS")
    : url.includes("assembly")
    ? (person = "ASSEMBLYMEMBERS")
    : url.includes("nyc")
    ? (person = "COUNCILMEMBERS")
    : "LEGISLATORS";

  const [toggleLegend, setToggleLegend] = useState(false);
  const [icon, setIcon] = useState("remove");
  const [toggleWindow, setToggleWindow] = useState("legend-container");

  let toggle = () => {
    setToggleLegend(!toggleLegend);
    if (toggleLegend) {
      setIcon("open_in_full");
      setToggleWindow("hide-legend");
    } else {
      setIcon("remove");
      setToggleWindow("legend-container");
    }
  };

  return (
    <div className={toggleWindow}>
      <span
        className="material-symbols-outlined toggle-legend-icon"
        onClick={toggle}
      >
        {icon}
      </span>
      <h5>LEGEND</h5>

      <div className="democrat-legend">
        <div className="circle"></div>
        DEMOCRATIC {person}
      </div>

      <div className="republican-legend">
        <div className="circle"></div>
        REPUBLICAN {person}
      </div>
      <p className="alert-text">
        Maps not loading correctly? Try zooming in or refreshing the page.
      </p>
    </div>
  );
}

export default Legend;
